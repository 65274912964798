import React from "react";
import pt from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "../ui/slider";
import * as styles from "./styles.module.scss";

const Multimedia = ({ images }) => {
  const ctfInititalImage = getImage(images[0]);
  return images.length <= 1 ? (
    <GatsbyImage image={ctfInititalImage} className={styles.multimediaImage} />
  ) : (
    <Slider>
      {images?.map((image, idx) => {
        const ctfImage = getImage(image);
        return (
          <GatsbyImage
            key={`image${idx}`}
            image={ctfImage}
            className={styles.multimediaImage}
          />
        );
      })}
    </Slider>
  );
};
Multimedia.propTypes = {
  images: pt.array,
};
Multimedia.defaultProps = {
  images: [],
};

export default Multimedia;
