import React from "react";
import pt from "prop-types";
import Helmet from "react-helmet";

const SEO = ({ description, lang, meta, keywords, title, author }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={title}
    meta={[
      {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8",
      },
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ]
      .concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : []
      )
      .concat(meta)}
  />
);

SEO.defaultProps = {
  lang: "de",
  meta: [],
  keywords: [],
  description: "",
};

SEO.propTypes = {
  description: pt.string,
  lang: pt.string,
  meta: pt.arrayOf(pt.object),
  keywords: pt.arrayOf(pt.string),
  title: pt.string.isRequired,
};

export default SEO;
