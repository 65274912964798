import pt from "prop-types";
import React, { useContext } from "react";
import Hero from "../../components/hero";
import { SettingsContext } from "../../context/settings.context";
import Footer from "../footer";
import Header from "../header";
import SEO from "../seo";
import CookieBanner from '../cookieBanner'

const Layout = ({ children, title, description, hero }) => {
  const siteSettings = useContext(SettingsContext);
  return (
    <>
      <SEO
        title={`${siteSettings.siteTitle} - ${title}`}
        description={description}
        author={siteSettings.siteAuthor}
      />
      <Header />
      {hero && <Hero {...hero} />}
      <main>{children}</main>
      <Footer siteSettings={siteSettings} />
      <CookieBanner siteSettings={siteSettings} />
    </>
  );
};

Layout.propTypes = {
  hero: pt.object,
  title: pt.string.isRequired,
  description: pt.string.isRequired,
};

Layout.defaultProps = {
  hero: null,
};

export default Layout;
