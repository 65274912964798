import { Link as GatsbyLink } from "gatsby";
import pt from "prop-types";
import React from "react";
import ArrowRight from "../../../../static/images/arrow-right.svg";
import { combine } from "../../../helpers/styles";
import Text from "../text";
import * as styles from "./styles.module.scss";

const Link = ({
  url,
  target,
  children,
  asButton,
  withIcon,
  className,
  ...props
}) => {
  if (!url) return null;

  const isExternal = url?.startsWith("http");
  
  if (isExternal) {
    return (
      <a
        href={url}
        target={target}
        rel="external noopener noreferrer"
        className={combine(
          styles.link,
          asButton && styles.button,
          url.transparent && asButton && styles.transparent,
          className
        )}
      >
        {asButton ? (
          <Text as="span" type="button">
            {children}
          </Text>
        ) : (
          children
        )}
        {withIcon && <ArrowRight className={styles.icon} />}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={url}
      target={target}
      className={combine(
        styles.link,
        asButton && styles.button,
        url.transparent && asButton && styles.transparent,
        className
      )}
      {...props}
    >
      {asButton ? (
        <Text as="span" type="button">
          {children}
        </Text>
      ) : (
        children
      )}
      {withIcon && <ArrowRight className={styles.icon} />}
    </GatsbyLink>
  );
};

Link.propTypes = {
  url: pt.string,
  className: pt.string,
  asButton: pt.bool,
  withIcon: pt.bool,
};

Link.defaultProps = {
  url: "",
  className: "",
  asButton: false,
  withIcon: false,
};

export default Link;
