import React from "react";
import pt from "prop-types";
import SlickSlider from "react-slick";
import Arrow from "./components/arrow";
import * as styles from "./styles.module.scss";
import { combine } from "../../../helpers/styles";

const Slider = ({
  children,
  dots,
  infinite,
  speed,
  slidesToShow,
  slidesToScroll,
  arrowsColor,
  sliderOnMobile,
}) => {
  const sliderSettings = {
    dots: dots,
    infinite: infinite,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    prevArrow: <Arrow direction="previous" arrowsColor={arrowsColor} />,
    nextArrow: <Arrow direction="next" arrowsColor={arrowsColor} />,
  };
  return (
    <SlickSlider
      {...sliderSettings}
      className={combine(sliderOnMobile && styles.sliderOnMobile)}
    >
      {children}
    </SlickSlider>
  );
};

Slider.propTypes = {
  dots: pt.bool,
  infinite: pt.bool,
  speed: pt.number,
  slidesToShow: pt.number,
  slidesToScroll: pt.number,
};

Slider.defaultProps = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default Slider;
