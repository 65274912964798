// extracted by mini-css-extract-plugin
export var testimonial = "styles-module--testimonial--VEsvk";
export var left = "styles-module--left--6yXNJ";
export var center = "styles-module--center--GmlnC";
export var right = "styles-module--right--GgNPz";
export var leftLg = "styles-module--leftLg--yAsQR";
export var centerLg = "styles-module--centerLg--E8wXR";
export var rightLg = "styles-module--rightLg--VPCv0";
export var header = "styles-module--header--YFh55";
export var image = "styles-module--image--SrQ+r";
export var nameAndDescription = "styles-module--nameAndDescription--rCWjQ";
export var quote = "styles-module--quote--x-uBx";
export var nameAndDescriptionSm = "styles-module--nameAndDescriptionSm--jqtfl";