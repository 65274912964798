import mapboxgl from "!mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import pt from "prop-types";
import React, { useEffect, useRef } from "react";
import * as styles from "./styles.module.scss";

const Map = ({ latitude, longitude, zoom }) => {
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map?.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitude, latitude],
      zoom: zoom,
      attributionControl: false,
    });
    const marker = new mapboxgl.Marker({ color: 'black'})
    .setLngLat([longitude, latitude])
    .addTo(map.current);
  }, []);
  return <div ref={mapContainer} className={styles.mapContainer} />;
};

Map.propTypes = {
  latitude: pt.number,
  longitude: pt.number,
  zoom: pt.number,
};
Map.defaultProps = {
  latitude: -5,
  longitude: 38,
  zoom: 9,
};

export default Map;
