import React, { useContext } from "react";
import { linkProptypes } from "../../components/ui/link";
import { SettingsContext } from "../../context/settings.context";
import DesktopHeader from "./components/desktopHeader";
import MobileHeader from "./components/mobileHeader";
import * as styles from "./styles.module.scss";

const Header = () => {
  const {
    logo,
    navigation,
    navigationCta,
    copyright,
    legalLinks,
    socialLinks,
  } = useContext(SettingsContext);

  return (
    <div className={styles.header}>
      <DesktopHeader
        logo={logo}
        navItems={navigation}
        cta={navigationCta}
        className={styles.desktopHeader}
      />
      <MobileHeader
        logo={logo}
        navItems={navigation}
        copyright={copyright}
        legalLinks={legalLinks}
        socialLinks={socialLinks}
        className={styles.mobileHeader}
      />
    </div>
  );
};

export default Header;
