import React from "react";
import pt from "prop-types";
import * as styles from "./styles.module.scss";
import Text from "../ui/text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { combine } from "../../helpers/styles";

const TestimonialItem = ({
  image,
  name,
  description,
  quote,
  alignmentDesktop,
  alignmentMobile,
  className,
}) => {
  const ctfImage = getImage(image);
  return (
    <div
      className={combine(
        styles.testimonial,
        alignmentDesktop && styles[alignmentDesktop],
        alignmentMobile && styles[alignmentMobile]
      )}
    >
      <div className={styles.header}>
        {image && <GatsbyImage image={ctfImage} className={styles.image} />}
        <div className={styles.nameAndDescription}>
          {name && (
            <Text as="h3" type="h4" variant="bold">
              {name}
            </Text>
          )}
          {description && (
            <Text as="span" type="copy">
              {description}
            </Text>
          )}
        </div>
      </div>
      {quote && (
        <Text type="h5" className={styles.quote}>
          {quote}
        </Text>
      )}
      <div className={styles.nameAndDescriptionSm}>
        {name && (
          <Text as="span" type="h4" variant="bold">
            {name}
          </Text>
        )}
      </div>
    </div>
  );
};
TestimonialItem.propTypes = {
  image: pt.object,
  name: pt.string,
  description: pt.string,
  quote: pt.string,
  alignmentDesktop: pt.oneOf(["leftLg", "centerLg", "rightLg"]),
  alignmentMobile: pt.oneOf(["left", "center", "right"]),
  className: pt.string,
};
TestimonialItem.defaultProps = {
  image: {},
  name: "",
  description: "",
  quote: "",
  alignmentDesktop: "leftLg",
  alignmentMobile: "center",
  className: "",
};

export default TestimonialItem;
