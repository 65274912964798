import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import pt from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { combine } from "../../helpers/styles";
import Link, { linkProptypes } from "../ui/link";
import Text from "../ui/text";
import * as styles from "./styles.module.scss";

const Hero = ({
  tagline,
  headline,
  backgroundImage,
  withOverlay,
  link,
  alignment,
}) => {
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      className={combine(styles.hero, withOverlay && styles.withOverlay)}
    >
      <div
        className={combine(
          styles.innerContainer,
          alignment && styles[alignment]
        )}
      >
        <Container className={styles.heroContainer}>
          {tagline && (
            <Text type="h4" variant="bold">
              {tagline}
            </Text>
          )}
          {headline && (
            <Text as="h2" type="h1" variant="bold">
              {headline}
            </Text>
          )}
          {link && (
            <Link {...link} className={styles.link}>
              <Text as="span" type="h4" variant="bold">
                {link.text}
              </Text>
            </Link>
          )}
        </Container>
      </div>
    </BackgroundImage>
  );
};

Hero.propTypes = {
  tagline: pt.string,
  headline: pt.string,
  backgroundImage: pt.object,
  withOverlay: pt.bool,
  link: pt.shape(linkProptypes),
  alignment: pt.oneOf(["left", "center", "right"]),
};

Hero.defaultProps = {
  tagline: "",
  headline: "",
  backgroundImage: null,
  withOverlay: false,
  link: null,
  alignment: "center",
};

export default Hero;
