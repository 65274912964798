import React from 'react';
import pt from 'prop-types';
import Text from '../text';
import { combine } from '/src/helpers/styles';
import * as styles from './styles.module.scss';

const Button = ({ text, type, transparent, className, onClick }) => (
  <button
    onClick={onClick}
    className={combine(
      styles.button,
      styles[type],
      transparent && styles.transparent,
      className
    )}
  >
    <Text as="span" type={'button'}>
      {text}
    </Text>
  </button>
);

Button.propTypes = {
  text: pt.string.isRequired,
  onClick: pt.func.isRequired,
  type: pt.oneOf(['primary', 'secondary']).isRequired,
  className: pt.string,
  transparent: pt.bool,
};

Button.defaultProps = {
  className: '',
  transparent: false,
};

export default Button;
