// extracted by mini-css-extract-plugin
export var textVisual = "styles-module--textVisual--6eSwh";
export var right = "styles-module--right--lQ5sr";
export var textContainer = "styles-module--textContainer--3u2M4";
export var left = "styles-module--left--9afzf";
export var bottom = "styles-module--bottom--M8CwT";
export var imageContainer = "styles-module--imageContainer--p1KL8";
export var center = "styles-module--center--VRbcy";
export var image = "styles-module--image--BPLxe";
export var link = "styles-module--link--c0eJ8";
export var headline = "styles-module--headline--Be5hn";
export var text = "styles-module--text--RuHXT";
export var tagline = "styles-module--tagline--EREyG";