import pt from "prop-types";
import React from "react";
import { combine } from "../../helpers/styles";
import Icon from "../ui/icon";
import Link, { linkProptypes } from "../ui/link";
import Text from "../ui/text";
import * as styles from "./styles.module.scss";

const TeaserWithIcon = ({
  icon,
  headline,
  text,
  link,
  alignment,
  headlineAlignedWithIcon,
}) => {
  return (
    <div
      className={combine(
        styles.gridItemContainer,
        alignment && styles[alignment]
      )}
    >
      <div className={headlineAlignedWithIcon && styles.flex}>
        {icon && <Icon icon={icon} className={styles.icon} />}
        {headline && (
          <Text as="h3" type="h5" variant="bold">
            {headline}
          </Text>
        )}
      </div>
      {text && <Text className={styles.text}>{text}</Text>}
      {link && (
        <Link {...link} className={styles.link}>
          <Text as="span" type="h4" variant="bold">
            {link.text}
          </Text>
        </Link>
      )}
    </div>
  );
};
TeaserWithIcon.propTypes = {
  headline: pt.string,
  text: pt.string,
  link: pt.shape(linkProptypes),
  alignment: pt.oneOf(["left", "center", "right"]),
  headlineAlignedWithIcon: pt.bool,
};
TeaserWithIcon.defaultProps = {
  headline: "",
  text: "",
  link: null,
  alignment: "left",
  headlineAlignedWithIcon: false,
};

export default TeaserWithIcon;
