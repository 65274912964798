import React from "react";
import pt from "prop-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { combine } from "../../helpers/styles";
import * as styles from "./styles.module.scss";
import Link from "../ui/link";
import Text from "../ui/text";

const RichText = ({ content }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: function renderCopyText(_node, children) {
        return <Text>{children}</Text>;
      },
      [BLOCKS.HEADING_1]: function renderHeading1(_node, children) {
        return (
          <Text as="h1" type="h1" className={styles.hl}>
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_2]: function renderHeading2(_node, children) {
        return (
          <Text as="h2" type="h2" className={combine(styles.hl, styles.h2)}>
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_3]: function renderHeading3(_node, children) {
        return (
          <Text as="h3" type="h3" className={combine(styles.hl, styles.h3)}>
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_4]: function renderHeading4(_node, children) {
        return (
          <Text as="h4" type="h4" className={combine(styles.hl, styles.h4)}>
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_5]: function renderHeading5(_node, children) {
        return (
          <Text as="h5" type="h5" className={combine(styles.hl, styles.h5)}>
            {children}
          </Text>
        );
      },
      [BLOCKS.HR]: function renderEmbeddedHR(_node, children) {
        return children;
      },
      [BLOCKS.OL_LIST]: function renderEmbeddedUL(_node, children) {
        return <ol className={styles.ol}>{children}</ol>;
      },
      [BLOCKS.UL_LIST]: function renderEmbeddedUL(_node, children) {
        return <ul className={styles.ul}>{children}</ul>;
      },
      [BLOCKS.LIST_ITEM]: function renderEmbeddedLI(_node, children) {
        return <li>{children}</li>;
      },
      [INLINES.HYPERLINK]: function renderHyperlink(node, children) {
        const link = { url: node.data.uri, target: "_blank" };
        return <Link {...link}>{children}</Link>;
      },
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return <div>{renderRichText(content, options)}</div>;
};

export const richTextPropTypes = {
  raw: pt.string,
};

RichText.propTypes = {
  content: pt.shape(richTextPropTypes).isRequired,
};

export default RichText;
