import React, { useEffect, useState } from 'react';
import pt from "prop-types";
import ReactGA from 'react-ga';
import Button from '../../components/ui/button';
import Text from '../../components/ui/text';
import * as styles from './cookieBanner.module.scss';
import ReactMarkdown from 'react-markdown'
import DC_Bakery from './dc_bakery';

DC_Bakery.__init();
const CookieBanner = ({
 siteSettings
}) => {
  
  const {
    googleAnalyticsId,
    cookiesHeadline,
    cookiesText,
    functionalCookiesText,
    marketingCookiesText,
    acceptSelectedCookiesText,
    acceptAllCookiesText
  } = siteSettings;
  const [isClient, setIsClient] = useState(false);
  const [selection, setSelection] = useState([true, false]);
  const [cookieIsSet, setCookieIsSet] = useState(null);

  const typesOfCookies = [
    'functionalCookies',
    'marketingCookies',
  ];
  const getCookieLabelText = (cookie) => {
    switch (cookie) {
      case "functionalCookies":
        return functionalCookiesText
      case "marketingCookies":
        return marketingCookiesText
      default:
      return functionalCookiesText
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
      setCookieIsSet(cookieExists('cookieConsent'));
    }
  }, []);

  useEffect(() => {
    if (!!cookieIsSet) {
      DC_Bakery.flush();

      if (cookieExists('statisticalCookies')) {
        ReactGA.initialize(googleAnalyticsId);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }, [cookieIsSet]);

  const changeSelection = (index) => {
    let state = [...selection];
    state[index] = !state[index];
    setSelection(state);
  };

  const writeCookie = (selectAll = false) => {
    let selectedCookies = selection;
    if (selectAll) selectedCookies = [true, true];

    createCookie('cookieConsent');
    setCookieIsSet(true);

    selectedCookies.forEach(
      (cookie, index) => cookie && createCookie(typesOfCookies[index])
    );

    DC_Bakery.clean();
  };

  const createCookie = (cookie) => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    return (document.cookie = `${cookie}=true; expires=${cookieDate.toUTCString()}; path=/;`);
  };

  const cookieExists = (cookie) => {
    return document?.cookie?.indexOf(cookie) > -1;
  };

  return cookieIsSet !== null ? (
    !cookieIsSet ? (
      <div className={styles.cookieBanner}>
        <div className={styles.cookieBannerInner}>
        <Text type="h3" variant="bold" className={styles.headline}>
        {cookiesHeadline}
        </Text>
        <Text  className={styles.text}>
          <ReactMarkdown>{cookiesText.cookiesText}</ReactMarkdown>
        </Text>
        <form className={styles.formWrapper}>
          {typesOfCookies.map((type, index) => (
            <label
              className={styles.checkboxLabel}
              key={index}
              htmlFor={type}
            >
              <div className={styles.checkboxWrapper}>
                <input
                  name={type}
                  id={type}
                  type="checkbox"
                  disabled={index === 0}
                  checked={selection[index]}
                  onChange={() => changeSelection(index)}
                />
                <span className={styles.slider}></span>
              </div>
              {getCookieLabelText(type)}
            </label>
          ))}
        </form>
        <div className={styles.buttonContainer}>
          <Button
            type="secondary"
            className={styles.button}
            text={acceptSelectedCookiesText}
            onClick={() => writeCookie()}
          />
          <Button
            type="primary"
            className={styles.button}
            text={acceptAllCookiesText}
            onClick={() => writeCookie(true)}
          />
        </div>
      </div>
    </div>
    ) : (
      // add tracking stuff dynamically
      ''
    )
  ) : (
    ''
  );
};

CookieBanner.propTypes = {
  siteSettings: pt.shape({
    googleAnalyticsId: pt.string,
    cookiesHeadline: pt.string,
    cookiesText: pt.shape(
      {
        cookiesText: pt.string
      }
    ),
    functionalCookiesText: pt.string,
    marketingCookiesText: pt.string,
    acceptSelectedCookiesText: pt.string,
    acceptAllCookiesText: pt.string
  })
};

CookieBanner.defaultProps = {
  siteSettings: {
    googleAnalyticsId: "",
    cookiesHeadline: "Cookies and data protection",
    cookiesText: {
      cookiesText: ""
    },
    functionalCookiesText: "Functional Cookies",
    marketingCookiesText: "Marketing Cookies",
    acceptSelectedCookiesText: "Accept Selected Cookies",
    acceptAllCookiesText: "Accept All Cookies"
  }
};

export default CookieBanner;
