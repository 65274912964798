// extracted by mini-css-extract-plugin
export var cookieBanner = "cookieBanner-module--cookieBanner--CIFi3";
export var cookieBannerInner = "cookieBanner-module--cookieBannerInner--7EYIl";
export var headline = "cookieBanner-module--headline--tz6Fy";
export var text = "cookieBanner-module--text--8tce0";
export var checkbox = "cookieBanner-module--checkbox--oiDXr";
export var buttonContainer = "cookieBanner-module--buttonContainer--rmxk0";
export var button = "cookieBanner-module--button--QkkcN";
export var formWrapper = "cookieBanner-module--formWrapper--zjUWP";
export var checkboxLabel = "cookieBanner-module--checkboxLabel--fD7gZ";
export var checkboxWrapper = "cookieBanner-module--checkboxWrapper--Vs9Di";
export var slider = "cookieBanner-module--slider--0ADQO";