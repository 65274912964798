// extracted by mini-css-extract-plugin
export var logoLink = "styles-module--logoLink--7tGUC";
export var logo = "styles-module--logo--QE0W6";
export var menu = "styles-module--menu--hxnPp";
export var showing = "styles-module--showing--yQN45";
export var navigation = "styles-module--navigation--WSj-4";
export var link = "styles-module--link--rtAhm";
export var copyAndLegal = "styles-module--copyAndLegal--0GavW";
export var socials = "styles-module--socials--2QwlB";
export var copyright = "styles-module--copyright--tGlg6";
export var legalLinks = "styles-module--legalLinks--7sbWC";
export var socialLink = "styles-module--socialLink--+WaB1";
export var icon = "styles-module--icon--WdN1o";