import React from "react";
import pt from "prop-types";
import ArrowRight from "../../../../../../static/images/slider-arrow-right.svg";
import ArrowLeft from "../../../../../../static/images/slider-arrow-left.svg";
import * as styles from "./styles.module.scss";

const Arrow = ({ className, style, onClick, arrowsColor, direction }) => {
  return (
    <button onClick={onClick} className={styles.button}>
      {direction === "previous" ? (
        <ArrowLeft
          className={className}
          style={{
            ...style,
            display: "block",
            zIndex: "1",
            fill: arrowsColor ? arrowsColor : "white",
          }}
        />
      ) : (
        <ArrowRight
          className={className}
          style={{
            ...style,
            display: "block",
            zIndex: "1",
            fill: arrowsColor ? arrowsColor : "white",
          }}
        />
      )}
    </button>
  );
};
Arrow.propTypes = {
  className: pt.string,
  style: pt.object,
  onClick: pt.func,
  direction: pt.oneOf(["previous", "next"]),
};
Arrow.defaultProps = {
  className: "",
  style: {},
  direction: "previous",
};

export default Arrow;
