// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--FS9TR";
export var container = "styles-module--container---yfA2";
export var logoLink = "styles-module--logoLink--kGSUV";
export var logo = "styles-module--logo--V2bHb";
export var metaInfo = "styles-module--metaInfo--uveA-";
export var metaInfospan = "styles-module--metaInfospan--ziikd";
export var copyright = "styles-module--copyright--m1dYF";
export var legalLinksContainer = "styles-module--legalLinksContainer--Hj1MB";
export var legalLink = "styles-module--legalLink--EUWuj";
export var socialLinks = "styles-module--socialLinks--Oheqa";
export var socialLink = "styles-module--socialLink--JcEH-";
export var icon = "styles-module--icon--H+3zq";