import React from "react";
import { renderDynamicComponents } from "../../components";
import { SettingsContext } from "../../context/settings.context";
import Layout from "../../modules/layout";

const ContentPage = ({ pageContext }) => {
  const { title, seoDescription, content, hero, node_locale, siteSettings } =
    pageContext;
  return (
    <SettingsContext.Provider value={siteSettings}>
      <Layout title={title} description={seoDescription} hero={hero}>
        {content && renderDynamicComponents(content)}
      </Layout>
    </SettingsContext.Provider>
  );
};

export default ContentPage;
