import { GatsbyImage, getImage } from "gatsby-plugin-image";
import pt from "prop-types";
import React from "react";
import { combine } from "../../helpers/styles";
import Link, { linkProptypes } from "../ui/link";
import Text from "../ui/text";
import * as styles from "./styles.module.scss";

const TextVisual = ({
  tagline,
  headline,
  headlineType,
  content,
  link,
  image,
  imagePosition,
  alignment,
}) => {
  const ctfImage = getImage(image);

  return (
    <div
      className={combine(
        styles.textVisual,
        image && imagePosition && styles[imagePosition]
      )}
    >
      <div
        className={combine(
          styles.textContainer,
          alignment && styles[alignment]
        )}
      >
        {tagline && (
          <Text as="span" className={styles.tagline}>
            {tagline}
          </Text>
        )}
        {headline && (
          <Text as="h2" type={headlineType} variant="bold" className={styles.headline}>
            {headline}
          </Text>
        )}
        {content?.text && <Text className={styles.text}>{content?.text}</Text>}
        {link && (
          <Link {...link} className={styles.link}>
            <Text as="span" type="h4" variant="bold">
              {link.text}
            </Text>
          </Link>
        )}
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <GatsbyImage
            className={styles.image}
            alt={`${headline} - image`}
            image={ctfImage}
          />
        </div>
      )}
    </div>
  );
};
TextVisual.propTypes = {
  tagline: pt.string,
  headline: pt.string,
  text: pt.string,
  link: pt.shape(linkProptypes),
  image: pt.object,
  imagePosition: pt.oneOf(["left", "bottom", "right"]),
  alignment: pt.oneOf(["left", "center", "right"]),
  headlineType: pt.string,
};

TextVisual.defaultProps = {
  tagline: "",
  headline: "",
  text: "",
  link: null,
  imagePosition: "right",
  alignment: "left",
  headlineType: "h2",
};

export default TextVisual;
